

























import { BlAvatarAv, BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-dweb'
import { BlTextAv } from '@bukalapak/bazaar-mweb'
import Vue from 'vue'

import scaleDirective from './scale'
import { testimonials } from './testimonials'

export default Vue.extend({
  name: 'LandingTestimonialsDesktop',
  directives: {
    scale: scaleDirective,
  },
  components: {
    BlTextAv,
    BlFlexContainerMv,
    BlFlexItemAv,
    BlAvatarAv,
  },
  data() {
    return {
      testimonials,
    }
  },
})
