let scaleEventListener

export default {
  bind(el, binding) {
    scaleEventListener = () => {
      if (document.body.clientWidth < binding.value.minScreenWidth) {
        const scale = document.body.clientWidth / binding.value.minScreenWidth
        el.style.transform = `scale(${scale})`
      } else {
        el.style.transform = 'none'
      }
    }
    el.style.minWidth = `${binding.value.minScreenWidth}px`
    el.style.transformOrigin = 'left top'

    window.addEventListener('resize', scaleEventListener)
    scaleEventListener()
  },
  unbind() {
    window.removeEventListener('resize', scaleEventListener)
  },
}
