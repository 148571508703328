export const testimonials = [
  {
    description:
      'Selama ini pesan di BukaBangunan, barang selalu datang tepat waktu, selain itu juga ada banyak promo dan cashback yang diberikan. Terima kasih BukaBangunan!',
    avatar: require('~/assets/images/landing-page/testimonials/Pak Budi.png'),
    name: 'Pak Budi',
    title: 'Barang selalu datang tepat waktu',
    type: 'TB Berkat Jaya',
  },
  {
    description:
      'Saya sudah beberapa kali order di BukaBangunan, harga bersaing, banyak promo menarik, sehingga membantu memperbesar margin toko saya. Terima kasih BukaBangunan!',
    avatar: require('~/assets/images/landing-page/testimonials/Pak Evan.png'),
    name: 'Pak Evan',
    title: 'Harga bersaing, banyak promo menarik',
    type: 'TB Sumber Pembangunan',
  },
  {
    description:
      'Sekarang membeli bahan bangunan menjadi praktis dan aplikasinya juga mudah digunakan! Sudah beberapa kali transaksi, kualitas sesuai dan harga yang ditawarkan lebih murah dari distributor offline',
    avatar: require('~/assets/images/landing-page/testimonials/Pak Achmad.png'),
    name: 'Pak Achmad',
    title: 'Aplikasinya juga mudah digunakan',
    type: 'Toko Bangunan',
  },
]
